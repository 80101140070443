const TYPE = {
  OPEN: 'open',
  CLOSE: 'close',
};
const SECONDS_IN_DAY = 24 * 60 * 60;

export const sortByValue = arr => {
  const returnableArray = [...arr];
  return returnableArray.sort((a = {}, b = {}) => (a.value > b.value ? 1 : -1));
};

const findClosingTime = (times, minTime) => {
  const { value } =
    times.find(({ type, value }) => type === TYPE.CLOSE && value > minTime) ||
    {};
  return value;
};

const relativeDiffForWeek = daysFromStartOfWeek => timeDiffForDay =>
  timeDiffForDay === undefined
    ? timeDiffForDay
    : SECONDS_IN_DAY * daysFromStartOfWeek + timeDiffForDay;

export const weekDaysInOrder = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

const rollingNextDay = day => {
  const orderedIndex = weekDaysInOrder.indexOf(day);
  const nextIndex =
    orderedIndex === weekDaysInOrder.length - 1 ? 0 : orderedIndex + 1;
  return weekDaysInOrder[nextIndex];
};

/**
 * Parse API key-value response data of format { type, value } into collection format expected by the view: { day,
 * ranges }, where ranges indicate the time difference from the start of the week, rather than the day.
 */
const parseData = (data = {}) => {
  const weekDays = Object.keys(data);

  return weekDays.map(day => {
    const orderedIndex = weekDaysInOrder.indexOf(day);
    const nextDay = rollingNextDay(day);
    const getTimeDiff = relativeDiffForWeek(orderedIndex);
    const nextDayCloseTimes = (data[nextDay] || [])
      .filter(({ type }) => type === TYPE.CLOSE)
      .map(({ value, ...rest }) => ({
        value: value + SECONDS_IN_DAY,
        ...rest,
      }));

    const dayData = sortByValue([
      ...(data[day] || []),
      ...(nextDayCloseTimes || []),
    ]);

    const ranges = dayData
      .map(({ type, value }) => {
        if (type === TYPE.OPEN) {
          return [
            getTimeDiff(value),
            getTimeDiff(findClosingTime(dayData, value)),
          ].filter(Boolean);
        }
        return false;
      })
      .filter(Boolean);
    return { day, ranges };
  });
};

export default parseData;

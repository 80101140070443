import '@vetixy/circular-std';

const colors = {
  bodyCopy: '#202125',
  box: '#ffffff',
  background: '#f8f8f8',
  border: 'rgba(32, 33, 37, 0.08)',
  closed: 'rgba(32, 33, 37, 0.42)',
  today: '#57cc14',
};

const fonts = {
  CircularStd: 'CircularStd',
};

const fontWeights = {
  black: 800,
  bold: 600,
  medium: 500,
  book: 400,
};

export default {
  colors,
  fonts,
  fontWeights,
};

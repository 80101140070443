import styled from 'styled-components';
import { color, flexbox } from 'styled-system';

export const AppWrapper = styled.div(
  {
    textAlign: 'center',
    minHeight: '100%',
    display: 'flex',
  },
  flexbox,
  color,
);
AppWrapper.defaultProps = {
  backgroundColor: 'background',
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'center',
};

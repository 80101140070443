import React from 'react';
import { ThemeProvider } from 'styled-components';

import theme from '../theme';
import OpeningHours from '../OpeningHours';

import { AppWrapper } from './style';

import weekData from '../API';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <AppWrapper>
        <OpeningHours data={weekData} />
      </AppWrapper>
    </ThemeProvider>
  );
};

export default App;

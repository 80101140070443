import styled from 'styled-components';
import { typography, border, color, flexbox } from 'styled-system';

const fontDefaults = {
  fontFamily: 'CircularStd',
};

export const Box = styled.div(
  {
    margin: 32,
    width: 327,
    boxSizing: 'border-box',
    boxShadow: '0 2px 4px 2px rgba(0, 0, 0, 0.08)',
    textAlign: 'left',
    padding: '23px 33px 31px',
  },
  color,
  border,
);

Box.defaultProps = {
  color: 'bodyCopy',
  backgroundColor: 'box',
  borderRadius: 10,
};

export const Heading = styled.div(
  {
    paddingBottom: 13,
  },
  typography,
  color,
  border,
);

Heading.defaultProps = {
  borderBottom: '1px solid',
  borderColor: 'bodyCopy',
  ...fontDefaults,
  fontWeight: 'bold',
  fontSize: 24,
};

export const Icon = styled.img({
  width: 18,
  height: 18,
  marginRight: 6,
  display: 'inline-block',
});

export const List = styled.ul({
  listStyleType: 'none',
  margin: 0,
  padding: 0,
});

export const ListItem = styled.li(
  {
    paddingTop: 7,
    paddingBottom: 8,
    display: 'flex',
  },
  flexbox,
  border,
);

ListItem.defaultProps = {
  borderBottom: '1px solid',
  borderColor: 'border',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
};

export const ListItemHeading = styled.span(
  {
    display: 'flex',
  },
  flexbox,
  typography,
  color,
);

ListItemHeading.defaultProps = {
  color: 'bodyCopy',
  alignItems: 'baseline',
  ...fontDefaults,
  fontWeight: 'medium',
  fontSize: 16,
};

export const ListItemContent = styled.span(
  {
    display: 'flex',
  },
  flexbox,
  typography,
  color,
);

ListItemContent.defaultProps = {
  color: 'bodyCopy',
  ...fontDefaults,
  fontSize: 16,
  fontWeight: 'book',
};

export const ActiveTag = styled.span(
  {
    fontFamily: 'CircularStd',
    textTransform: 'uppercase',
    paddingLeft: 8,
    paddingRight: 8,
  },
  typography,
  color,
);

ActiveTag.defaultProps = {
  color: 'today',
  ...fontDefaults,
  fontWeight: 'medium',
  fontSize: 12,
};

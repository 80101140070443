import React, { Fragment } from 'react';
import { format, addSeconds, startOfWeek, getDay } from 'date-fns';

import parseData, { weekDaysInOrder } from './parseData';
import {
  Heading,
  List,
  ListItem,
  ListItemHeading,
  ListItemContent,
  ActiveTag,
  Icon,
  Box,
} from './style';

import timeIconImage from './TimeIcon@2x.png';

const headingByDay = {
  sunday: 'Sunday',
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
};

const diffToDate = diff => addSeconds(startOfWeek(Date.now()), diff);
const diffToText = diff => format(diffToDate(diff), 'h A');
const isToday = day => weekDaysInOrder.indexOf(day) === getDay(Date.now());
const timeRangesToText = ranges =>
  ranges.map(r => r.map(diffToText).join(' - ')).join(', ');

const OpeningHours = ({ data }) => (
  <Fragment>
    <Box width={327}>
      <Heading>
        <Icon src={timeIconImage} /> Opening hours
      </Heading>
      <List>
        {parseData(data).map(({ day, ranges = [] }) => {
          const hasTimes = ranges.length;
          return (
            <ListItem key={day}>
              <ListItemHeading>
                {headingByDay[day] || 'Unknown day'}{' '}
                {isToday(day) && <ActiveTag>today</ActiveTag>}
              </ListItemHeading>
              <ListItemContent color={hasTimes ? undefined : 'closed'}>
                {hasTimes ? timeRangesToText(ranges) : 'Closed'}
              </ListItemContent>
            </ListItem>
          );
        })}
      </List>
    </Box>
  </Fragment>
);

export default OpeningHours;
